.mynetwork {
    margin-top: 20px;
}

.mynetwork-container {
    display: flex;
    justify-content: center;
}

.left-menu {
    border: 1px solid #eee;
    background: #fff;
    border-radius: 10px;
}

.side-menu-item {
    display: flex;
    flex-direction: row;
    padding: 4px 10px;
    cursor: pointer;
}
.side-menu-item:hover{
    background: #eee;
}

.itme-svg {
    margin-right: 10px;
}
.main-section {
    width: 65%;
    border: 1px solid #eee;
    margin-left: 20px;
    padding: 10px;
    border-radius: 2px;
    background: #fff;
}
h6.section-heading {
    margin: 0 10px 10px;
}

.user-cards {
    list-style-type: none;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.user-card-list {
    width: 32%;
    margin-bottom: 20px;
    padding: 6px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 4px;
}

.user-card-img {
    margin: 0 auto;
}
.user-card-img img {
    width: 100px;
    height: 100px;
}
.user-card-info {
    display: flex;
    flex-direction: column;
    min-height: 100px;
}

.user-card-name {
    font-size: 18px;
    font-weight: 500;
}
.item-link {
    display: flex;
    width: 100%;
}
.user-card-headline, .user-card-connection {
    font-size: 12px;
}
.user-card-action button {
    padding: 5px 20px;
    background: #fff;
    color: #252525;
    font-weight: 500;
    border: 1px solid #1877f2;
}
.user-card-action button:hover {
    background: #1877f2;
    color: #fff;
}
.item-link.active {
    border-right: 5px solid;

}
.site-header {
    position: relative;
    background: #F0F8FF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.grid-container {
    padding: 0 30px 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}
.navbar-wrapper {
    display: flex;
    align-items: center;
    align-content: flex-start;
    position: relative;

}

.logo-container,
.logo-container a {
    display: contents;
}

.nav-wrapper {
    display: inline;
    margin-left: auto;
}

.nav-menu {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    align-items: center;
}

.nav-menu-item {
    padding: 16px 12px;
    margin-right: 10px;
    cursor: pointer;
}
.nav-menu-item a,.drop-menu a {
    color: #252525;
    font-weight: 500;
}
.header-logo {
    width: 150px;
    vertical-align: middle;
}
.search-box {
    margin: 0 0 0 15px;
}
.search-list {
    position: absolute;
    max-height: 280px;
    overflow-y: auto;
    list-style-type: none;
    background: #fff;
    max-width: fit-content;
    border: 1px solid #eee;
    border-radius: 0 0 10px 10px;

}
.search-list-items {
    padding: 5px 16px;
    margin: 0 0 5px 0;
    border-bottom: 1px dotted #eee;
}
.search-list-items a {
    color: #2d2d2d;
}
.user-menu-icon {
    font-size: 25px;
    margin: auto 0 auto 0;
    cursor: pointer;
}

.user-icon {
    padding: 8px 0 0 10px;
}
.search-box input:focus-within {
    width : 120%;
    transition: width .7s ease;
}

.drop-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 100px;
    right: 20px;
    top: 100%;
    border: 1px solid #252525;
    z-index: 1;
    background: #F0F8FF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.drop-menu.show {
    display: block;
}

.drop-menu-item {
    width: 100%;
    padding: 5px 20px;
}
.drop-menu::before {
    content: "";
    position: absolute;
    border-color: rgba(76, 76, 76, 0);
    border: solid transparent;
    border-bottom-color: #bbb;
    border-width: 11px;
    margin-left: -10px;
    top: -21px;
    right: 10px;
    z-index: 1;
}
.drop-menu-item:hover, .drop-menu-item:hover a {
    color: #fff;
    background: #1877f2;
}
.nav-menu-item:hover {
    background-color: #eee;
}
.notification {display: flex;padding: 4px 10px;border: 1px solid #eee;flex-direction: column;}

span.notification-time {
    font-size: 12px;
}
@media screen and (max-width: 768px) {
    .search-box{
        display: none;
    }
  }
  .drop-menu.show {
    display: block;
    max-height: 400px;
    word-wrap: break-word;
    overflow: overlay;
}
.post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 0 15px 0 15px;
}

.post-body {
    border: 1px solid #eee;
    border-radius: 2px;
    margin: 0 0 20px 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

}
p.img-post-text a {
    cursor: pointer;
}
.post-meta svg {
    margin: 0 10px 0 6px;
}
.post-items {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 14px;
}

.post-meta {
    border-radius: 10px 10px 0 0;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-bottom: 2px;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
}
.more-option-items {
    display: none;
    background-color: #fff;
    min-width: 100px;
    right: 20px;
    top: 100%;
    border-radius: 5px;
    border: 1px solid #252525;
    z-index: 1;
    background: #F0F8FF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding: 0 6px;
    opacity: 0;
    position: absolute;
}
.more-option-items.show{
    display: block;
    opacity: 1;
}
.post-content {
    display: flex;
    align-items: center;
}

.post-action {
    display: flex;
    align-content: flex-end;
    margin: 0;
    background: #fff;
    border-radius: 0 0 10px 10px;
    margin-top: 10px;
}

.like-btn,
.comment-button {
    width: 50%;
    border-radius: 10px;
    display: flex;
    font-weight: 500;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    position: relative;
}

.comment-section {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
}

.comment-author-info {
    font-size: 14px;
}

li.post-comment {
    background: #f0f2f5;
    width: fit-content;
    padding: 8px 12px;
    margin: 0 0 10px 0;
    border-radius: 14px;
}

.comment-content {
    margin: 0 0 0 10px;
    max-width: 300px;
    word-wrap: break-word;
}

ol.comment-list {
    padding: 10px;
    list-style-type: none;
    display: block;
    height: 100%;
    max-height: 300px;
}

.comment-respond {
    padding: 10px;
    display: flex;
    justify-content: center;
}

.comment-respond input {
    width: 70%;
    margin: 0 14px 0 0;
}
.post-action svg {
    margin: 0 6px 0 0;
}
p.img-post-text,.post-content p{
    background: #fff;
    padding: 10px;
    word-wrap: break-word;
    overflow: auto;
}
.post-meta img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.post-meta span {
    margin-left: 10px;
    font-size: 18px;
    color: #252525;
}

.post-meta a {
    display: flex;
    align-items: center;
}
.more-option {
    padding: 8px;
    position: relative;
    cursor: pointer;
}

.delete-btn.btn {
    cursor: pointer;
}
.reactions-tooltip {
    position: absolute;
    display: flex;
    bottom: 30px;
    background: #fff;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 50px;
    justify-content: space-evenly;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.reaction-btn {
    padding: 4px 8px;
    margin-left: 4px;
    border-radius: 10px;
    display: flex;
    font-size: 22px;
    stroke: #252525;
    stroke-width: 1px;
}

.reaction-btn svg {
    margin: 0;
}
.reaction-btn:hover svg {
    transform: scale(1.5);
    transition: 0.5s;
}
.like {
    fill: #2F58CD;
}
.love {
    fill: #D21312;
}
.congo {
    fill: #00a693;
}
.insightful {
    fill: #F39F5A;
}
.support {
    fill: #F05941;
}
.funny {
    fill: #78C1F3;
}
.post-action-counts {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #eee;
    font-weight: 500;
    color: #707070;
    font-size: 12px;
}
*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


:root {
  --text-primary: #333;
  --text-secondary: #666; 
  --text-light: #999; 
  --text-link: #007BFF; 


  --background-primary: #f7f8f9; 
  --background-secondary: #f5f5f5; 


  --button-primary-bg: #007BFF; 
  --button-primary-text: #fff; 
  --button-secondary-bg: #6c757d; 
  --button-secondary-text: #fff; 
}


html{
  font-family: ans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;

}
body{
  font-weight: 400;
  font-size: 17px;
  line-height: 1.775;
  text-transform: none;
  overflow-x: hidden;
  background-color: var(--background-primary);
  color: var(--contrast);
  font-family: system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Noto Sans,Roboto,Ubuntu,Helvetica,sans-serif;
}
a {
  color: var(--text-link);
  text-decoration: none;
}
h1,h2,h3,h4,h5,h6 {
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: 600
}

h1 {
  font-size: 36px;
  margin-bottom: 10px;
  line-height: 1.2em;
  text-transform: none
}

h2 {
  font-size: 28px;
  margin-bottom: 10px;
  line-height: 1.2em;
  text-transform: none
}

h3 {
  font-size: 24px;
  margin-bottom: 10px;
  line-height: 1.2em;
  text-transform: none
}

h4 {
  font-size: 20px
}

h5 {
  font-size: 18px
}

h4,h5,h6 {
  margin-bottom: 20px
}
.primary-button {
  background-color: #007BFF;
  color: #fff;
  padding: 5px 16px; 
  border: none;
  border-radius: 4px; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.primary-button:hover {
  background-color: #0056b3; 
}

.secondary-button {
  background-color: #fff; 
  color: #007BFF; 
  padding: 5px 16px; 
  border: none;
  border-radius: 4px; 
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #007BFF;
}

.secondary-button:hover {
  background-color: #495057;
}



img {
  width: 100%;
}


.container.login > h2 {
  margin-block: 1rem;
  padding-block: 0.6rem;
  color: #000;
}

.container.login > form {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.container.login div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.container.login input {
  border: none;
  padding: 0.5rem;
  border-bottom: 1px solid gray;
  font-size: 1.1rem;
  outline: none;
}
.login-body {
  width: 100vw;
  height: 100vh;
  background: #eef5fd;
  background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);
}
.container.login input::placeholder {
  font-size: 0.9rem;
  font-style: italic;
}

/* .container.login {
  max-width: 400px;
  padding: 20px;
  min-width: 380px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  } */
  .container.login{
    position: absolute;
    min-width: 380px;
    transform: translate(50%, -50%);
    top: 50%;
    right: 50%;
    max-width: 400px;
    border: 1px solid;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border: 2px solid rgb(255, 255, 255, 0.3);
    background-color: rgb(255, 255, 255, 0.3);
  }
  
  input[type="username"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    /* margin-bottom: 10px; */
  }

  button:hover {
    background-color: #002d5e;
  }
  .google-login {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.google-login div {
  display: flex;
  flex-direction: row!important;
  justify-content: center;
  align-content: center;
}
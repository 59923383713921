/* PostComponent.css */

.post1 {
    margin: 20px auto;
    width: 500px;
    background-color: #ffffff;
    border: 1px solid #e9ebee;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    padding: 16px;
    border-radius: 5px;
  }
  
  .post {
    margin-bottom: 20px;
  }
  
  .post p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .post button {
    background-color: #1877f2;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
  }
  
  .post button:hover {
    text-decoration: underline;
  }
  
  textarea {
    width: 95%;
    height: 100px; /* Set a fixed height for the post input box */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  button {
    background-color: #1877f2; /* Facebook blue color */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #1157d3; /* Darker blue on hover */
  }
  
  .comments {
    margin-top: 10px;
    padding-left: 20px;
  }
  
  .post-add-action {
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    margin: 10px auto;
}

.post-add-action input {
    border: 0;
    padding: 0;
    margin: 0;
}  
.post-image-button {
    border: 1px solid #eee;
    padding: 4px 10px;
    margin-right: 10px;
    cursor: pointer;
}
.post-image-button input {
  padding: 0;
  border: 0;
  border-radius: 0;
  position: absolute;
  padding: 20px;
  z-index: 2;
  opacity: 0;
  width: 6vw;
  margin-top: 8px;
  cursor: pointer;
}
.post-button {
  text-align: center;
  border: 1px solid #0C356A;
  padding: 2px 30px;
  margin-left: auto;
  border-radius: 50px;
  background: #095caf;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}
.post-button:hover{
  background: #fff;
  color: #095caf;
  border: 1px solid #095caf;

}
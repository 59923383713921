.feed-wrapper {
    display: flex;
    margin-top: 20px;
}

.left-sidebar,.right-sidebar {
    width: 24%;
}

.feed-container {
    border: 1px solid #eeeeee;
    padding: 14px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
}
.user-post {
    margin: 0 0 20px 0;
    padding: 16px 0 5px 0;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #bbb;
}

.user-post-input textarea {
    width: 90%;
    margin: 0 auto;
    resize: none;
}

.user-post-input {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}